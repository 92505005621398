.btn,
.swal2-container .swal2-popup .swal2-styled.swal2-confirm,
.swal2-container .swal2-popup .swal2-styled.swal2-cancel {
	background: url(/images/btn.png) no-repeat !important;
	background-size: 100% 100% !important;
	// background-color: #062739 !important;
	font-size: tovw(24);
	text-transform: uppercase;
	color: #000000 !important;
	height: tovw(52);
	line-height: tovw(50);
	padding: 0 tovw(15);
	outline: none !important;
	// border: 1px solid #ff8400 !important;
	outline: none !important;
	box-shadow: none !important;
	border-radius: tovw(2);
	min-width: tovw(153);
	position: relative;
	font-weight: bold;
	font-family: $roboto !important;
	// text-shadow: 1px 1px 0 #66a6aa, 1px -1px 0 #66a6aa, -1px 1px 0 #66a6aa, -1px -1px 0 #66a6aa, 1px 0px 0 #66a6aa, 0px 1px 0 #66a6aa, -1px 0px 0 #66a6aa, 0px -1px 0 #66a6aa;
	transition: all 0.25s ease;
	@include min-screen(1200px) {
		&:hover {
			color: #000 !important;
			// background-color: #ff8400 !important;
			// border: 1px solid #fff !important;
			background: url(/images/btn-active.png) no-repeat !important;
			background-size: 100% 100% !important;
			transform: scale(1.1);
			// text-shadow: 1px 1px 0 #cdf6cb, 1px -1px 0 #cdf6cb, -1px 1px 0 #cdf6cb, -1px -1px 0 #cdf6cb, 1px 0px 0 #cdf6cb, 0px 1px 0 #cdf6cb, -1px 0px 0 #cdf6cb, 0px -1px 0 #cdf6cb;
		}
	}
	&--fw {
		width: 100%;
		min-width: 100%;
	}
}
// .swal2-container .swal2-popup .swal2-styled.swal2-cancel {
//   // @extend %grayscale;
//   // background: url(/images/btn-cancel.png) no-repeat !important;
//   background-size: 100% 100% !important;
//   color: #ffab44 !important;
// }
