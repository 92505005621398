@keyframes flashing {
	0%,
	100% {
		background-color: #a1d435;
	}
	50% {
		background-color: #fff600;
	}
}
@keyframes pointing {
	0%,
	20%,
	30%,
	100% {
		transform: rotate(0deg);
	}
	15%,
	25% {
		transform: rotate(3deg);
	}
}
@keyframes appearing {
	0%,
	100% {
		transform: scale(1);
	}
	50% {
		transform: scale(0.95);
	}
}
@keyframes floating {
	0%,
	100% {
		transform: translateY(0);
	}
	50% {
		transform: translateY(-1rem);
	}
}
@keyframes glowing {
	0%,
	100% {
		opacity: 1;
	}
	50% {
		opacity: 0.6;
	}
}
@keyframes heartBeat {
	0%,
	40%,
	80%,
	100% {
		transform: scale(1);
	}
	20%,
	60% {
		transform: scale(1.1);
	}
}
@keyframes heartBeatLow {
	0%,
	40%,
	80%,
	100% {
		transform: scale(1);
	}
	20%,
	60% {
		transform: scale(1.05);
	}
}
@keyframes ping {
	0%,
	20%,
	40%,
	100% {
		transform: translateY(0);
	}
	10%,
	30% {
		transform: translateY(-1rem);
	}
}
@keyframes scaling {
	0%,
	100% {
		transform: scale(1) rotate(-8deg);
	}
	50% {
		transform: scale(1.1) rotate(-8deg);
	}
}
@keyframes bounce {
	from,
	20%,
	53%,
	80%,
	to {
		-webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
		animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}

	40%,
	43% {
		-webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
		animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
		-webkit-transform: translate3d(0, -10px, 0);
		transform: translate3d(0, -10px, 0);
	}

	70% {
		-webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
		animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
		-webkit-transform: translate3d(0, -5px, 0);
		transform: translate3d(0, -5px, 0);
	}

	90% {
		-webkit-transform: translate3d(0, -2px, 0);
		transform: translate3d(0, -2px, 0);
	}
}
@keyframes won {
	0%,
	100% {
		transform: scale(1);
	}
	30%,
	70% {
		transform: scale(1.2);
	}
}
@keyframes move-shaking {
	0% {
		transform: translate(0, 0) rotate(0deg);
	}
	25% {
		transform: translate(3px, 3px) rotate(3deg);
	}
	50% {
		transform: translate(0, 0) rotate(0eg);
	}
	75% {
		transform: translate(-3px, 3px) rotate(-3deg);
	}
	100% {
		transform: translate(0, 0) rotate(0deg);
	}
}

@keyframes tada {
	from {
		transform: scale3d(1, 1, 1);
	}
	10%,
	20% {
		transform: scale3d(0.95, 0.95, 0.95) rotate3d(0, 0, 1, -2deg);
	}
	30%,
	50%,
	70%,
	90% {
		transform: scale3d(1.05, 1.05, 1.05) rotate3d(0, 0, 1, 2deg);
	}

	40%,
	60%,
	80% {
		transform: scale3d(1.05, 1.05, 1.05) rotate3d(0, 0, 1, -2deg);
	}

	to {
		transform: scale3d(1, 1, 1);
	}
}

@keyframes rotateVertical {
	0%,
	50%,
	100% {
		transform: rotateX(0deg);
	}
	25% {
		transform: rotateX(-4deg);
	}
	75% {
		transform: rotateX(3deg);
	}
}
@keyframes lx {
	0%,
	50%,
	100% {
		transform: translate(0, 0);
	}
	25% {
		transform: translate(tovw(-10), 0);
	}
	75% {
		transform: translate(tovw(10), 0);
	}
}
@keyframes rotateHorizon {
	0%,
	50%,
	100% {
		transform: rotateY(0deg);
	}
	25% {
		transform: rotateY(-4deg);
	}
	75% {
		transform: rotateY(4deg);
	}
}
@keyframes shake {
	2% {
		transform: translate(5px, 7px) rotate(0.5deg);
	}
	4% {
		transform: translate(5px, 9px) rotate(3.5deg);
	}
	6% {
		transform: translate(-2px, -5px) rotate(3.5deg);
	}
	8% {
		transform: translate(4px, 8px) rotate(-1.5deg);
	}
	10% {
		transform: translate(2px, 9px) rotate(-0.5deg);
	}
	12% {
		transform: translate(-5px, -4px) rotate(-2.5deg);
	}
	14% {
		transform: translate(-6px, -1px) rotate(3.5deg);
	}
	16% {
		transform: translate(-8px, 8px) rotate(2.5deg);
	}
	18% {
		transform: translate(4px, 5px) rotate(-0.5deg);
	}
	20% {
		transform: translate(3px, -3px) rotate(3.5deg);
	}
	22% {
		transform: translate(0px, -6px) rotate(-1.5deg);
	}
	24% {
		transform: translate(-6px, -6px) rotate(3.5deg);
	}
	26% {
		transform: translate(-8px, -6px) rotate(0.5deg);
	}
	28% {
		transform: translate(-3px, -6px) rotate(1.5deg);
	}
	30% {
		transform: translate(7px, 8px) rotate(-1.5deg);
	}
	32% {
		transform: translate(7px, 6px) rotate(2.5deg);
	}
	34% {
		transform: translate(-7px, -7px) rotate(-2.5deg);
	}
	36% {
		transform: translate(7px, 3px) rotate(-0.5deg);
	}
	38% {
		transform: translate(-6px, -1px) rotate(3.5deg);
	}
	40% {
		transform: translate(-3px, 4px) rotate(-0.5deg);
	}
	42% {
		transform: translate(-1px, 10px) rotate(3.5deg);
	}
	44% {
		transform: translate(9px, 3px) rotate(3.5deg);
	}
	46% {
		transform: translate(-3px, 8px) rotate(-2.5deg);
	}
	48% {
		transform: translate(-5px, 7px) rotate(3.5deg);
	}
	50% {
		transform: translate(-9px, -5px) rotate(0.5deg);
	}
	52% {
		transform: translate(2px, 4px) rotate(0.5deg);
	}
	54% {
		transform: translate(2px, -9px) rotate(-2.5deg);
	}
	56% {
		transform: translate(-2px, 9px) rotate(-1.5deg);
	}
	58% {
		transform: translate(0px, 4px) rotate(0.5deg);
	}
	60% {
		transform: translate(-6px, 10px) rotate(3.5deg);
	}
	62% {
		transform: translate(-1px, 3px) rotate(2.5deg);
	}
	64% {
		transform: translate(5px, -5px) rotate(0.5deg);
	}
	66% {
		transform: translate(5px, 3px) rotate(3.5deg);
	}
	68% {
		transform: translate(1px, -7px) rotate(-2.5deg);
	}
	70% {
		transform: translate(7px, 3px) rotate(0.5deg);
	}
	72% {
		transform: translate(-9px, -8px) rotate(-2.5deg);
	}
	74% {
		transform: translate(9px, 10px) rotate(-1.5deg);
	}
	76% {
		transform: translate(-1px, -7px) rotate(1.5deg);
	}
	78% {
		transform: translate(-7px, -4px) rotate(-1.5deg);
	}
	80% {
		transform: translate(8px, -3px) rotate(3.5deg);
	}
	82% {
		transform: translate(9px, 5px) rotate(0.5deg);
	}
	84% {
		transform: translate(-4px, -7px) rotate(1.5deg);
	}
	86% {
		transform: translate(-7px, 9px) rotate(2.5deg);
	}
	88% {
		transform: translate(0px, 10px) rotate(1.5deg);
	}
	90% {
		transform: translate(7px, 4px) rotate(-2.5deg);
	}
	92% {
		transform: translate(-9px, 10px) rotate(-1.5deg);
	}
	94% {
		transform: translate(4px, -8px) rotate(0.5deg);
	}
	96% {
		transform: translate(9px, 0px) rotate(1.5deg);
	}
	98% {
		transform: translate(8px, -3px) rotate(1.5deg);
	}
	0%,
	100% {
		transform: translate(0, 0) rotate(0);
	}
}
@keyframes wobble-hor-top {
	0%,
	100% {
		-webkit-transform: translateX(0%);
		transform: translateX(0%);
		-webkit-transform-origin: 50% 50%;
		transform-origin: 50% 50%;
	}
	15% {
		-webkit-transform: translateX(-30px) rotate(6deg);
		transform: translateX(-30px) rotate(6deg);
	}
	30% {
		-webkit-transform: translateX(15px) rotate(-6deg);
		transform: translateX(15px) rotate(-6deg);
	}
	45% {
		-webkit-transform: translateX(-15px) rotate(3.6deg);
		transform: translateX(-15px) rotate(3.6deg);
	}
	60% {
		-webkit-transform: translateX(9px) rotate(-2.4deg);
		transform: translateX(9px) rotate(-2.4deg);
	}
	75% {
		-webkit-transform: translateX(-6px) rotate(1.2deg);
		transform: translateX(-6px) rotate(1.2deg);
	}
}
@keyframes heartbeat {
	from {
		-webkit-transform: scale(1);
		transform: scale(1);
		-webkit-transform-origin: center center;
		transform-origin: center center;
		-webkit-animation-timing-function: ease-out;
		animation-timing-function: ease-out;
	}
	10% {
		-webkit-transform: scale(0.91);
		transform: scale(0.91);
		-webkit-animation-timing-function: ease-in;
		animation-timing-function: ease-in;
	}
	17% {
		-webkit-transform: scale(0.98);
		transform: scale(0.98);
		-webkit-animation-timing-function: ease-out;
		animation-timing-function: ease-out;
	}
	33% {
		-webkit-transform: scale(0.87);
		transform: scale(0.87);
		-webkit-animation-timing-function: ease-in;
		animation-timing-function: ease-in;
	}
	45% {
		-webkit-transform: scale(1);
		transform: scale(1);
		-webkit-animation-timing-function: ease-out;
		animation-timing-function: ease-out;
	}
}

@keyframes type1medal1 {
	0% {
		transform: translate(0, 0) rotate(360deg);
	}
	25% {
		transform: translate(tovw(-10), tovw(-200));
	}
	50% {
		transform: translate(tovw(45), tovw(-300)) rotate(360deg);
	}
	100% {
		transform: translate(tovw(-207), tovw(-520));
	}
}
@keyframes type2medal1 {
	0% {
		transform: translate(0, 0) rotate(360deg);
	}
	25% {
		transform: translate(tovw(-120), tovw(-280));
	}
	50% {
		transform: translate(tovw(-35), tovw(-380)) rotate(360deg);
	}
	100% {
		transform: translate(tovw(-207), tovw(-520));
	}
}
@keyframes type3medal1 {
	0% {
		transform: translate(0, 0) rotate(360deg);
	}
	25% {
		transform: translate(tovw(-170), tovw(-280));
	}
	50% {
		transform: translate(tovw(-280), tovw(-200)) rotate(360deg);
	}
	100% {
		transform: translate(tovw(-207), tovw(-520));
	}
}
@keyframes type1medal2 {
	0% {
		transform: translate(0, 0) rotate(360deg);
	}
	25% {
		transform: translate(tovw(-10), tovw(-190));
	}
	50% {
		transform: translate(tovw(45), tovw(-290)) rotate(360deg);
	}
	100% {
		transform: translate(tovw(-127), tovw(-520));
	}
}
@keyframes type2medal2 {
	0% {
		transform: translate(0, 0) rotate(360deg);
	}
	25% {
		transform: translate(tovw(-20), tovw(-190));
	}
	50% {
		transform: translate(tovw(85), tovw(-90)) rotate(360deg);
	}
	100% {
		transform: translate(tovw(-127), tovw(-520));
	}
}
@keyframes type3medal2 {
	0% {
		transform: translate(0, 0) rotate(360deg);
	}
	25% {
		transform: translate(tovw(-150), tovw(-280));
	}
	50% {
		transform: translate(tovw(-25), tovw(-380)) rotate(360deg);
	}
	100% {
		transform: translate(tovw(-127), tovw(-520));
	}
}
@keyframes type1medal3 {
	0% {
		transform: translate(0, 0) rotate(360deg);
	}
	25% {
		transform: translate(tovw(-160), tovw(-100));
	}
	50% {
		transform: translate(tovw(-80), tovw(-200)) rotate(360deg);
	}
	100% {
		transform: translate(tovw(-47), tovw(-520));
	}
}
@keyframes type2medal3 {
	0% {
		transform: translate(0, 0) rotate(360deg);
	}
	25% {
		transform: translate(tovw(60), tovw(-280));
	}
	50% {
		transform: translate(tovw(-70), tovw(-200)) rotate(360deg);
	}
	100% {
		transform: translate(tovw(-47), tovw(-520));
	}
}
@keyframes type3medal3 {
	0% {
		transform: translate(0, 0) rotate(360deg);
	}
	25% {
		transform: translate(tovw(-140), tovw(-280));
	}
	50% {
		transform: translate(tovw(-40), tovw(-380)) rotate(360deg);
	}
	100% {
		transform: translate(tovw(-47), tovw(-520));
	}
}
@keyframes type1medal4 {
	0% {
		transform: translate(0, 0) rotate(360deg);
	}
	25% {
		transform: translate(tovw(110), tovw(-285));
	}
	50% {
		transform: translate(tovw(270), tovw(-350)) rotate(360deg);
	}
	100% {
		transform: translate(tovw(33), tovw(-520));
	}
}
@keyframes type2medal4 {
	0% {
		transform: translate(0, 0) rotate(360deg);
	}
	25% {
		transform: translate(tovw(350), tovw(-95));
	}
	50% {
		transform: translate(tovw(220), tovw(-210)) rotate(360deg);
	}
	100% {
		transform: translate(tovw(33), tovw(-520));
	}
}
@keyframes type3medal4 {
	0% {
		transform: translate(0, 0) rotate(360deg);
	}
	25% {
		transform: translate(tovw(75), tovw(-85));
	}
	50% {
		transform: translate(tovw(220), tovw(-410)) rotate(360deg);
	}
	100% {
		transform: translate(tovw(33), tovw(-520));
	}
}
@keyframes type1medal5 {
	0% {
		transform: translate(0, 0) rotate(360deg);
	}
	25% {
		transform: translate(tovw(150), tovw(-100));
	}
	50% {
		transform: translate(tovw(330), tovw(-150)) rotate(360deg);
	}
	100% {
		transform: translate(tovw(113), tovw(-520));
	}
}
@keyframes type2medal5 {
	0% {
		transform: translate(0, 0) rotate(360deg);
	}
	25% {
		transform: translate(tovw(-10), tovw(-200));
	}
	50% {
		transform: translate(tovw(20), tovw(-360)) rotate(360deg);
	}
	100% {
		transform: translate(tovw(113), tovw(-520));
	}
}
@keyframes type3medal5 {
	0% {
		transform: translate(0, 0) rotate(360deg);
	}
	25% {
		transform: translate(tovw(75), tovw(-280));
	}
	50% {
		transform: translate(tovw(180), tovw(-190)) rotate(360deg);
	}
	100% {
		transform: translate(tovw(113), tovw(-520));
	}
}
